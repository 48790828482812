import { routerDom } from '@smartfire/glue-ui';

// Type
const { generatePath } = routerDom;

const computedUrl = (base: string, routerPath?: any) => {
    return generatePath(base, routerPath);
};

export const urlConfig = {
    frontend: {
        auth: {
            login: {
                base: '/auth/login',
            },
            logout: {
                base: '/auth/logout',
            },
        },
        form: {
            home: {
                base: '/form',
            },
            step1: {
                base: '/form/1',
            },
            step2: {
                base: '/form/2',
            },
            step3: {
                base: '/form/3',
            },
            step4: {
                base: '/form/4',
            },
            step5: {
                base: '/form/5',
            },
            step6: {
                base: '/form/6',
            },
            step7: {
                base: '/form/7',
            },
            step8: {
                base: '/form/8',
            },
            step9: {
                base: '/form/9',
            },
        },
        home: {
            base: '/',
        },
        page: {
            detail: {
                base: '/:slug',
                computedUrl: (slug: string) => {
                    return computedUrl(urlConfig.frontend.page.detail.base, {
                        slug,
                    });
                },
            },
        },
        contact: {
            base: '/contact',
        },
        legalNotice: {
            base: '/mentions-legales',
        },
    },
    admin: {
        firms: {
            list: {
                base: '/admin/firms',
            },
            edit: {
                base: '/admin/firms/:id',
                computedUrl: (id: string) => {
                    return computedUrl(urlConfig.admin.firms.edit.base, {
                        id,
                    });
                },
            },
        },
    },
};
