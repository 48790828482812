import {
    ChildrenBlock,
    EditableImg,
    smartProps,
    useEditor,
    useInspectorControl,
    useSmartProp,
} from '@smartfire/glue-ui/block';
import { registerBlock } from '@smartfire/glue-ui';
import { InnerBlocks } from '@wordpress/block-editor';
import { PanelBody, SelectControl } from '@wordpress/components';
import clsx from 'clsx';

import styles from './block.module.scss';

const Inspector = () => {
    const [theme, setTheme] = useSmartProp('theme');
    return (
        <PanelBody title="Style">
            <SelectControl
                label="Style"
                value={theme}
                onChange={setTheme}
                options={[
                    { value: 'style1', label: 'Style 1' },
                    { value: 'style2', label: 'Style 2' },
                ]}
            />
        </PanelBody>
    );
};

const Block = ({ theme }: { theme: string }) => {
    const { isEditing } = useEditor();
    useInspectorControl(Inspector);

    return (
        <div className={clsx(styles.block, !isEditing && styles.frontPage, styles[theme])}>
            <EditableImg className={styles.image} smartProp="image" />

            {isEditing ? (
                <InnerBlocks
                    allowedBlocks={[
                        'core/heading',
                        'core/link',
                        'core/paragraph',
                        'core/image',
                        'core/columns',
                        'core/separator',
                        'smartfire/button',
                        'smartfire/spacer',
                    ]}
                />
            ) : (
                <ChildrenBlock />
            )}
        </div>
    );
};

export default registerBlock(
    'horizontal-tile',
    {
        Block,
        smartProps: {
            image: smartProps.image,
            theme: smartProps.string.withDefault('style1'),
        },
        setup: {
            title: 'Tuile horizontale',
        },
    },
    'smartfire',
);
