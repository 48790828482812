import { registerFormField, smartProps, useEditor } from '@smartfire/glue-ui/block';
import { SelectControl } from 'src/components/inputs/SelectController';
import { useFormContext, useForm } from 'react-hook-form';
import { options } from '../../../config/optionsContact';

type Props = {
    labelField: string;
    label: string;
    isRequired: boolean;
    typeField: string;
};

export const Select = ({ label, labelField, isRequired }: Props) => {
    const { isEditing } = useEditor();
    const form = useFormContext();
    const formAdmin = useForm();

    return (
        <SelectControl
            name={label}
            label={labelField}
            control={isEditing ? formAdmin.control : form.control}
            smartProp={'labelField'}
            rules={
                isRequired
                    ? {
                          required: 'Ce champ est requis.',
                      }
                    : undefined
            }
            isAdmin={isEditing}
            options={options}
            defaultLabel={'Choisissez un sujet'}
        />
    );
};

export default registerFormField('select', {
    Block: Select,
    type: 'select',
    smartProps: {
        labelField: smartProps.string.withDefault('label'),
    },
    setup: {
        title: 'Select',
    },
});
