export enum FormStep {
    BasicInfo = 'BasicInfo',
    ContactInfo = 'ContactInfo',
    PrevoyanceChoix = 'PrevoyanceChoix',
    MutuelleSanteChoix = 'MutuelleSanteChoix',
    ContactCorrespondantFasttFptt = 'ContactCorrespondantFasttFptt',
    ContactCorrespondantConcentrateurHeures = 'ContactCorrespondantConcentrateurHeures',
    ContactCorrespondantProtectionSociale = 'ContactCorrespondantProtectionSociale',
}

export const FormStepOrder = [
    FormStep.BasicInfo,
    FormStep.ContactInfo,
    FormStep.MutuelleSanteChoix,
    FormStep.PrevoyanceChoix,
    FormStep.ContactCorrespondantFasttFptt,
    FormStep.ContactCorrespondantConcentrateurHeures,
    FormStep.ContactCorrespondantProtectionSociale,
];
