export enum FormStatus {
    CREATED = 'CREATED',
    MAIL_SENT_TO_PRINT = 'MAIL_SENT_TO_PRINT',
    PRINT_SENT = 'PRINT_SENT',
    NPAI = 'NPAI',
    CANCELLED = 'CANCELLED',
    FILLING = 'FILLING',
    FILLED = 'FILLED',
    SENT = 'SENT',
}
