import { EditableDiv, smartProps, useEditor, useInspectorControl, useSmartProp } from '@smartfire/glue-ui/block';
import { registerBlock } from '@smartfire/glue-ui';
import { PanelBody, PanelRow, SelectControl, TextControl, ToggleControl } from '@wordpress/components';
import { MediaUpload, MediaUploadCheck } from '@wordpress/block-editor';
import clsx from 'clsx';
import style from './block.module.scss';
import { ReactComponent as Icon } from '../../assets/download.svg';
import { useSelect } from '@wordpress/data';

function Inspector() {
    const [theme, setTheme] = useSmartProp('theme');
    const [linkType, setLinkType] = useSmartProp('linkType');
    const [link, setLink] = useSmartProp('link');
    const [fileId, setFileId] = useSmartProp('fileId');

    const [targetBlank, setTargetBlank] = useSmartProp('targetBlank');
    const [showDownload, setShowDownload] = useSmartProp('showDownload');

    const onSelectFile = (file: any) => {
        setFileId(file.id);
        setLink(file.url);
    };

    const file: any = useSelect((select) => (fileId ? select('core').getMedia(fileId) : undefined));

    return (
        <>
            <PanelBody title="Apparence" initialOpen={true}>
                <PanelRow>
                    <SelectControl
                        label="Style"
                        value={theme}
                        onChange={setTheme}
                        options={[
                            { value: 'style1', label: 'Style 1' },
                            { value: 'style2', label: 'Style 2' },
                        ]}
                    />
                </PanelRow>

                <PanelRow>
                    <ToggleControl
                        label="Afficher l'icône Télécharger"
                        checked={showDownload}
                        onChange={setShowDownload}
                    />
                </PanelRow>
            </PanelBody>
            <PanelBody title="Lien" initialOpen={true}>
                <PanelRow>
                    <SelectControl
                        label="Type de lien"
                        value={linkType}
                        onChange={(value) => {
                            setLinkType(value);
                            setLink('');
                            setFileId(0);
                        }}
                        options={[
                            { value: 'lienext', label: 'Lien' },
                            { value: 'file', label: 'Fichier' },
                        ]}
                        style={{ width: '100%' }}
                    />
                </PanelRow>
                <PanelRow>
                    {
                        {
                            file: (
                                <MediaUploadCheck>
                                    <MediaUpload
                                        onSelect={onSelectFile}
                                        allowedTypes={['application']}
                                        value={fileId}
                                        render={({ open }) => (
                                            <>
                                                <button onClick={open}>
                                                    {fileId && file ? (
                                                        <>
                                                            {file.title.rendered}
                                                            <br />
                                                            Remplacer le fichier
                                                        </>
                                                    ) : (
                                                        'Choisir un fichier'
                                                    )}
                                                </button>
                                            </>
                                        )}
                                    />
                                </MediaUploadCheck>
                            ),
                            lienext: <TextControl label="Lien" type="lienext" value={link} onChange={setLink} />,
                        }[linkType]
                    }
                </PanelRow>
                <PanelRow>
                    <ToggleControl
                        label="Ouvrir dans un nouvel onglet"
                        checked={targetBlank}
                        onChange={setTargetBlank}
                    />
                </PanelRow>
            </PanelBody>
        </>
    );
}

function Block({
    theme,
    link,
    disabled,
    showDownload,
    targetBlank = false,
}: {
    theme: string;
    link: string;
    disabled: boolean;
    showDownload: boolean;
    targetBlank: boolean;
}) {
    useInspectorControl(Inspector);

    const { isEditing } = useEditor();
    const Wrapper = isEditing || disabled ? 'div' : 'a';

    return (
        <div className={style.container}>
            <Wrapper href={link} target={targetBlank ? '_blank' : ''} rel="noreferrer">
                <div className={clsx(style.button, style[theme], disabled && style.disabled)}>
                    <EditableDiv
                        smartProp="text"
                        tagName="span"
                        allowedFormats={['core/bold', 'core/italic', 'core/text-color']}
                    />
                    {showDownload && <Icon className={style.icon} />}
                </div>
            </Wrapper>
        </div>
    );
}

export default registerBlock(
    'button',
    {
        Block,
        smartProps: {
            text: smartProps.string.withDefault(''),
            linkType: smartProps.string.withDefault('lienext'),
            link: smartProps.string.withDefault(''),
            fileId: smartProps.number.withDefault(0),
            theme: smartProps.string.withDefault('style1'),
            targetBlank: smartProps.bool.withDefault(false),
            showDownload: smartProps.bool.withDefault(false),
            disabled: smartProps.bool.withDefault(false),
        },
        setup: {
            title: 'Bouton',
        },
    },
    'smartfire',
);
