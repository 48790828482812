import { smartProps, useInspectorControl, useSmartProp } from '@smartfire/glue-ui/block';
import style from './block.module.scss';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import { registerBlock } from '@smartfire/glue-ui';
import { useMediaQuery } from '@material-ui/core';

function Inspector() {
    const [height, setHeight] = useSmartProp('height');
    const [mobileHeight, setMobileHeight] = useSmartProp('mobileHeight');

    return (
        <>
            <PanelBody title="Styles" initialOpen={true}>
                <PanelRow>
                    <TextControl
                        label="hauteur desktop (en px)"
                        type="number"
                        onChange={(val) => setHeight(val)}
                        step={10}
                        value={height}
                    />
                </PanelRow>
                <PanelRow>
                    <TextControl
                        label={'Hauteur mobile (en px)'}
                        value={mobileHeight}
                        type="number"
                        step={10}
                        onChange={(value) => setMobileHeight(value)}
                    />
                </PanelRow>
            </PanelBody>
        </>
    );
}

type BlockParams = { height: string; mobileHeight: string };

export function Block({ height, mobileHeight }: BlockParams) {
    useInspectorControl(Inspector);
    const isMobile = useMediaQuery(`maxWidth: ${style.mobileBreakpoint}`);
    const spacerHeight = isMobile ? mobileHeight : height;

    return (
        <div className={style.block}>
            <div className={style.content} style={{ height: spacerHeight + 'px' }}>
                <div className={style.spacer} />
            </div>
        </div>
    );
}

export default registerBlock(
    'spacer',
    {
        Block,
        smartProps: {
            height: smartProps.string.withDefault('50'),
            mobileHeight: smartProps.string.withDefault('30'),
        },
        setup: {
            title: 'Spacer',
        },
    },
    'smartfire',
);
