import { useEffect } from 'react';
import { routerDom } from '@smartfire/glue-ui';

const { useLocation } = routerDom;
const useScrollReset = (): null => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
};

export default useScrollReset;
