import { ApplicationResource } from '@smartfire/glue-ui/api';
import { FormStatus } from './FormStatus';
import { FormStep } from './FormStep';
import { FormFile } from './FormFile';

interface ErrorsInterface {
    error: string[];
}

const Error: ErrorsInterface = {
    error: [],
};

export default class Firm extends ApplicationResource {
    id: string;

    file: FormFile;

    formStep: FormStep[];

    dateAdded: Date;

    status: FormStatus;

    secretCode: string;

    tosAcceptationDate?: Date;

    static me<T extends typeof Firm>(this: T) {
        const url = this.urlRoot;
        return this.detail().extend({
            url() {
                return url;
            },
            schema: this,
        });
    }

    pk(): string {
        return this.id;
    }

    static submitStep1<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.partialUpdate().extend({
            url(param: any) {
                return url(param) + '/submit/infos';
            },
            schema: { firm: this, errors: Error },
            method: 'POST',
        });
    }

    static submitStep2<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit/contact';
            },
        });
    }

    static submitStep3<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit/choixMutuelle';
            },
        });
    }

    static submitStep4<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit/choixPrevoyance';
            },
        });
    }

    static submitStep5<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit/correspondantFastt';
            },
        });
    }

    static submitStep6<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit/correspondantConcentrateurHeures';
            },
        });
    }

    static submitStep7<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit/correspondantProtectionSociale';
            },
        });
    }

    static submitForm<T extends typeof Firm>(this: T) {
        const url = this.url.bind(this);
        return this.submitStep1().extend({
            url(param: any) {
                return url(param) + '/submit';
            },
        });
    }

    static resource = '/firm';
}
