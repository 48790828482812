import { smartProps, EditableDiv, useSmartProp } from '@smartfire/glue-ui/block';
import { registerBlock } from '@smartfire/glue-ui';

import styles from './block.module.scss';

const Block = () => {
    let [url] = useSmartProp('url');

    return (
        <div className={styles.block}>
            <EditableDiv className={styles.text} smartProp="text" allowedFormats={['core/bold']} />
            <a href={url} className={styles.link}>
                Mentions légales
            </a>
        </div>
    );
};

export default registerBlock(
    'footer',
    {
        Block,
        smartProps: {
            url: smartProps.string.withDefault('/mentions-legales'),
            text: smartProps.string.withDefault(''),
        },
        setup: {
            title: 'Footer',
        },
    },
    'smartfire',
);
