import style from './style.module.scss';

import { ReactComponent as Succes } from './success.svg';
import { ReactComponent as Email } from './email.svg';
import { Link } from '@smartfire/glue-ui/components';
import { urlConfig } from 'src/config/urlConfig';

export const FormStep9 = () => {
    return (
        <div className={style.lastStep}>
            <div className={style.success}>
                <div className={style.picto}>
                    <Succes />
                </div>

                <div className={style.text}>
                    <div className={style.bold}>Félicitations,</div>
                    <div>Vos données ont bien été transmises</div>
                </div>
            </div>

            <div className={style.emailConfirmation}>
                <div className={style.bold}>
                    Vous serez rapidement contacté par chacun des opérateurs de la Branche
                    <br /> afin de finaliser les démarches d'affiliation
                </div>
            </div>

            <div className={style.email}>
                <Email />

                <div className={style.text}>
                    <div>Vous souhaitez nous contacter ?</div>
                    <Link to={urlConfig.frontend.contact.base}>Cliquez-ici</Link>
                </div>
            </div>
        </div>
    );
};

export default FormStep9;
