import glue, {
    AnonymousIdentity,
    Authentication,
    DatabaseAuthentication,
    GlueAPI,
    Identity,
    LoginInstance,
} from '@smartfire/glue-ui';
import { FirmIdentity } from '../api/FirmIdentity';

import type Firm from '../api/Firm';
import { urlConfig } from './urlConfig';

export class FirmAuthentification extends DatabaseAuthentication {
    login(): LoginInstance {
        glue.browserHistory.push(urlConfig.frontend.auth.login.base, {
            redirect: glue.browserHistory.location,
        });
    }

    async loginWithFirm(sirenCode: string, secretCode: string): Promise<Identity> {
        try {
            await glue.api.post('/api/firm/login', {
                sirenCode: sirenCode,
                secretCode: secretCode,
            });
            const identityRequest = await glue.api.get<Firm>('/api/firm', {
                maxRedirects: 0,
            });
            if (200 === identityRequest.status) {
                this._currentUser = new FirmIdentity(identityRequest.data);
                this.eventManager.fire('login');
            }
            return this._currentUser;
        } catch (e) {
            throw new Error('Failed authentication');
        }
    }

    async logout(): Promise<void> {
        await glue.api.get('/api/firm/logout');
        this._currentUser = new AnonymousIdentity();
        this.eventManager.fire('logout');
        return undefined;
    }

    async init(localGlue: GlueAPI): Promise<Authentication> {
        try {
            const authRequest = await localGlue.api.get<Firm>('/api/firm', {
                maxRedirects: 0,
            });
            if (200 === authRequest.status) {
                this._currentUser = new FirmIdentity(authRequest.data);
            }
        } catch (e) {}
        return this;
    }
}
