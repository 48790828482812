import glue, { getGlueInternalRouteObject, GlueRouteObject } from '@smartfire/glue-ui';
import { lazy } from 'react';
import { urlConfig } from './config/urlConfig';
import { FormLayout, LoginLayout, MainLayout } from './components/layouts';
import { FirmGuard, FormGuard, GuestGuard } from './components/guards';

const AdminLayout = lazy(() => import(/* webpackChunkName: "project-admin-layout" */ './admin'));

// Pages
const SinglePageUI = lazy(() => import('./pages/SinglePageUI'));
const Home = lazy(() => import('./pages/Home'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const Contact = lazy(() => import('./pages/Contact'));
const LegalNotice = lazy(() => import('./pages/LegalNotices'));

const FormHome = lazy(() => import('./pages/Form/FormHome'));
const FormStep1 = lazy(() => import('./pages/Form/FormStep1'));
const FormStep2 = lazy(() => import('./pages/Form/FormStep2'));
const FormStep3 = lazy(() => import('./pages/Form/FormStep3'));
const FormStep4 = lazy(() => import('./pages/Form/FormStep4'));
const FormStep5 = lazy(() => import('./pages/Form/FormStep5'));
const FormStep6 = lazy(() => import('./pages/Form/FormStep6'));
const FormStep7 = lazy(() => import('./pages/Form/FormStep7'));
const FormStep8 = lazy(() => import('./pages/Form/FormStep8'));
const FormStep9 = lazy(() => import('./pages/Form/FormStep9'));

let routes: GlueRouteObject[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: urlConfig.frontend.page.detail.base,
                element: (
                    <FirmGuard>
                        <SinglePageUI />
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.contact.base,
                element: <Contact />,
            },
            {
                path: urlConfig.frontend.legalNotice.base,
                element: <LegalNotice />,
            },
            {
                path: '/',
                element: (
                    <FirmGuard>
                        <Home />
                    </FirmGuard>
                ),
            },
        ],
    },
    {
        path: '/auth',
        element: <LoginLayout />,
        children: [
            {
                path: urlConfig.frontend.auth.login.base,
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: urlConfig.frontend.auth.logout.base,
                element: <Logout />,
            },
        ],
    },
    {
        path: '/form',
        element: <FormLayout />,
        children: [
            {
                path: urlConfig.frontend.form.home.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormHome />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step1.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep1 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step2.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep2 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step3.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep3 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step4.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep4 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step5.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep5 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step6.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep6 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step7.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep7 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step8.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep8 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
            {
                path: urlConfig.frontend.form.step9.base,
                element: (
                    <FirmGuard>
                        <FormGuard>
                            <FormStep9 />
                        </FormGuard>
                    </FirmGuard>
                ),
            },
        ],
    },
    ...getGlueInternalRouteObject(),
    {
        path: '*',
        element: (
            <MainLayout>
                <NotFound />
            </MainLayout>
        ),
    },
];

glue.setRoutes(routes);

const FirmsList = lazy(() => import('./pages/admin/FirmsList'));
const FirmsEdit = lazy(() => import('./pages/admin/FirmsEdit'));
const UserEditPage = lazy(() => import('./pages/admin/UsersEdit'));

const AdminRoutes: GlueRouteObject[] = [
    {
        path: '/admin',
        element: <AdminLayout />,
        children: [
            {
                path: urlConfig.admin.firms.list.base,
                element: <FirmsList />,
            },
            {
                path: urlConfig.admin.firms.edit.base,
                element: <FirmsEdit />,
            },
            {
                path: '/admin/users/:id',
                element: <UserEditPage />,
            },
        ],
    },
];

glue.addRoutes(AdminRoutes);
