import { registerBlock } from '@smartfire/glue-ui';
import { smartProps, useInspectorControl, useSmartProp, useEditor } from '@smartfire/glue-ui/block';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import YouTube from 'react-youtube';
import style from './block.module.scss';

const Inspector = () => {
    const [idVideo, setIdVideo] = useSmartProp('idVideo');

    return (
        <PanelBody title={'Options'} initialOpen={true}>
            <PanelRow>
                <TextControl
                    label={"L'id de video Youtube correspond aux 11 caractères suivant le '=' dans l'url de la vidéo"}
                    value={idVideo}
                    onChange={setIdVideo}
                />
            </PanelRow>
        </PanelBody>
    );
};

const Block = ({ idVideo }: { idVideo: string }) => {
    useInspectorControl(Inspector);
    const { isEditing } = useEditor();

    return (
        <div className={style.block}>
            <YouTube
                videoId={idVideo}
                className={isEditing ? [style.video, style.videoEditing].join(' ') : style.video}
            />
        </div>
    );
};

export default registerBlock(
    'video-youtube',
    {
        Block,
        smartProps: {
            idVideo: smartProps.string.withDefault(''),
        },
        setup: {
            title: 'Vidéo Youtube',
        },
    },
    'smartfire',
);
