import type { ButtonHTMLAttributes } from 'react';
import style from './style.module.scss';

export const Button: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
    return (
        <div className={style.container}>
            <button className={style.button} type={'button'} {...props}>
                {children}
            </button>
        </div>
    );
};
