import { ChildrenBlock, EditableDiv, smartProps, useEditor } from '@smartfire/glue-ui/block';
import { registerBlock } from '@smartfire/glue-ui';
import { InnerBlocks } from '@wordpress/block-editor';
import styles from './block.module.scss';

const Block = () => {
    const { isEditing } = useEditor();

    return (
        <div className={styles.block}>
            <div className={styles.content}>
                <EditableDiv className={styles.number} smartProp="number" />

                {isEditing ? <InnerBlocks allowedBlocks={['core/heading', 'core/paragraph']} /> : <ChildrenBlock />}
            </div>
        </div>
    );
};

export default registerBlock(
    'chronology-item',
    {
        Block,
        smartProps: {
            number: smartProps.string.withDefault('1'),
        },
        setup: {
            title: 'Élément chronologie',
            parent: ['smartfire/chronology'],
        },
    },
    'smartfire',
);
