import { registerBlock } from '@smartfire/glue-ui';
import { smartProps, EditableImg } from '@smartfire/glue-ui/block';

import style from './block.module.scss';

const PageHeader = () => {
    return (
        <div className={style.block}>
            <EditableImg smartProp="media" />
        </div>
    );
};

export default registerBlock(
    'page-header',
    {
        Block: PageHeader,
        smartProps: {
            media: smartProps.any,
        },
        setup: {
            title: 'Page Header',
        },
    },
    'smartfire',
);
