import { registerFormField, smartProps, useEditor, useInspectorControl, useSmartProp } from '@smartfire/glue-ui/block';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import { TextareaControl } from 'src/components/inputs/TextareaController';
import { useFormContext, useForm } from 'react-hook-form';

type Props = {
    labelField: string;
    label: string;
    isRequired: boolean;
    typeField: string;
    placeholder: string;
};

const Inspector = () => {
    const [placeholder, setPlaceholder] = useSmartProp('placeholder');

    return (
        <PanelBody title="options" initialOpen={true}>
            <PanelRow>
                <TextControl value={placeholder} onChange={setPlaceholder} label={'Placeholder'} />
            </PanelRow>
        </PanelBody>
    );
};

export const Textarea = ({ label, labelField, isRequired, placeholder }: Props) => {
    useInspectorControl(Inspector);
    const { isEditing } = useEditor();
    const form = useFormContext();
    const formAdmin = useForm();

    return (
        <TextareaControl
            name={label}
            label={labelField}
            control={isEditing ? formAdmin.control : form.control}
            smartProp={'labelField'}
            rules={
                isRequired
                    ? {
                          required: 'Ce champ est requis.',
                      }
                    : undefined
            }
            isAdmin={isEditing}
            placeholder={placeholder}
        />
    );
};

export default registerFormField('textarea', {
    Block: Textarea,
    type: 'textarea',
    smartProps: {
        labelField: smartProps.string.withDefault('label'),
        placeholder: smartProps.string.withDefault('placeholder'),
    },
    setup: {
        title: 'Textarea',
    },
});
