import clsx from 'clsx';
import style from './style.module.scss';
import { Fade } from '@material-ui/core';
import { resolveValue, toast, Toaster } from 'react-hot-toast';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import type { ToastType } from 'react-hot-toast/dist/core/types';

const Icon = ({ type }: { type: ToastType }) => {
    switch (type) {
        case 'error':
            return <ErrorIcon className={style.icon} />;
        case 'success':
            return <CheckCircleIcon className={style.icon} />;
        default:
            return null;
    }
};

export const CustomToaster = () => {
    return (
        <Toaster
            position={'top-center'}
            gutter={0}
            containerStyle={{
                top: 0,
                right: 0,
                left: 0,
            }}
            toastOptions={{
                duration: 5000,
            }}
        >
            {(t) => {
                const classes = clsx({
                    [style.bar]: true,
                    [style.error]: t.type === 'error',
                    [style.success]: t.type === 'success',
                });
                return (
                    <Fade in={t.visible} timeout={600}>
                        <div className={classes}>
                            <Icon type={t.type} />
                            {resolveValue(t.message, t)}
                            <HighlightOffIcon onClick={() => toast.dismiss(t.id)} className={style.deleteIcon} />
                        </div>
                    </Fade>
                );
            }}
        </Toaster>
    );
};
