import { CommonLayout } from '../CommonLayout';

import style from './style.module.scss';

import login from '../../../assets/login.png';
import RenderGutenbergSetting from 'src/components/RenderGutenbergSetting';
import { Header } from 'src/blocks/menu';
import { LayoutParams, RenderSubRoutes } from '@smartfire/glue-ui';

export const LoginLayout = ({ children }: LayoutParams) => {
    return (
        <CommonLayout>
            <Header linkFile={'#'} loginContext={true} />
            <div className={style.container}>
                <div className={style.loginContent}>
                    <div className={style.content}>
                        <div>
                            <RenderSubRoutes children={children} />
                        </div>
                    </div>
                    <div className={style.layout}>
                        <img src={login} alt={'login'} />
                    </div>
                </div>
            </div>
            <RenderGutenbergSetting settingKey="footer" />
        </CommonLayout>
    );
};
