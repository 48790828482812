import { ChildrenBlock, useEditor } from '@smartfire/glue-ui/block';
import { InnerBlocks } from '@wordpress/block-editor';
import { registerBlock } from '@smartfire/glue-ui';

import styles from './block.module.scss';

const Block = () => {
    const { isEditing } = useEditor();

    return (
        <div className={styles.block}>
            {isEditing ? (
                <div className={styles.innerBlocksWrapper}>
                    <InnerBlocks allowedBlocks={['smartfire/chronology-item']} />
                </div>
            ) : (
                <div className={styles.childrenBlockWrapper}>
                    <ChildrenBlock />
                </div>
            )}
        </div>
    );
};

export default registerBlock(
    'chronology',
    {
        Block,
        smartProps: {},
        setup: {
            title: 'Chronologie',
        },
    },
    'smartfire',
);
