import { InnerBlocks } from '@wordpress/block-editor';
import { registerBlock } from '@smartfire/glue-ui';
import { useEditor, ChildrenBlock } from '@smartfire/glue-ui/block';
import style from './block.module.scss';
import clsx from 'clsx';

const Grid = () => {
    const { isEditing } = useEditor();

    const blockClasses = clsx({
        [style.block]: true,
        [style.admin]: isEditing,
    });

    return (
        <div className={blockClasses}>
            {isEditing ? (
                <InnerBlocks
                    template={[
                        ['core/group', {}],
                        ['core/group', {}],
                    ]}
                />
            ) : (
                <ChildrenBlock />
            )}
        </div>
    );
};

export default registerBlock(
    'grid',
    {
        Block: Grid,
        smartProps: {},
        setup: {
            title: 'Grid',
        },
    },
    'smartfire',
);
