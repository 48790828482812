import React from 'react';
import { useAuthentication } from '@smartfire/glue-ui';
import { FirmIdentity } from '../../api/FirmIdentity';

export const FirmGuard: React.FC = ({ children }) => {
    const authentication = useAuthentication();

    if (authentication.currentUser instanceof FirmIdentity) {
        // Access Granted
        return <>{children}</>;
    } else {
        // Firm not logged, redirect to login
        authentication.login();
        return null;
    }
};
