import { FirmContextProvider } from './context/FirmContext';
import glue, {
    AxiosTranslationProvider,
    ComponentKind,
    ComponentRender,
    HelmetTranslationProvider,
    LoadSettingContextProvider,
} from '@smartfire/glue-ui';

const Provider = () => {
    return (
        <HelmetTranslationProvider>
            <AxiosTranslationProvider>
                <FirmContextProvider>
                    <LoadSettingContextProvider settings={['header', 'footer']}>
                        <ComponentRender kind={ComponentKind.ROUTER} />
                    </LoadSettingContextProvider>
                </FirmContextProvider>
            </AxiosTranslationProvider>
        </HelmetTranslationProvider>
    );
};

glue.registerComponent({
    kind: ComponentKind.APP_PROVIDER,
    component: Provider,
    priority: 1,
});
