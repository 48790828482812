import { FrontendCommon } from '../FrontendCommon';
import { FormProgress } from './FormProgress';

import style from './style.module.scss';
import { LayoutParams, RenderSubRoutes } from '@smartfire/glue-ui';

export const FormLayout = ({ children }: LayoutParams) => {
    return (
        <FrontendCommon contentMobile={false}>
            <div className={style.content}>
                <FormProgress />
                <div>
                    <RenderSubRoutes children={children} />
                </div>
            </div>
        </FrontendCommon>
    );
};
