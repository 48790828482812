import './config';
import './theme.scss';
import './provider';
import glue, { DatabaseAuthentication } from '@smartfire/glue-ui';
import './blocks';
import './routes';
import { FirmAuthentification } from './config/FirmAuthentification';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';

const pathname = window.location.pathname.split('/');

// If we are on admin or login page for admin-ui
if (pathname[1] === 'admin' || pathname[1] === 'login') {
    glue.authentication = new DatabaseAuthentication();
} else {
    glue.authentication = new FirmAuthentification();
}

dayjs.extend(localizedFormat);
dayjs.locale('fr');

// @ts-ignore
window.glue = glue;
// Call render function
glue.renderDOM();
