import React from 'react';
import useFirm from 'src/hook/useFirm';
import { formConfig } from './config';

import style from './style.module.scss';
import clsx from 'clsx';
import { FormStep, FormStepOrder } from 'src/api/FormStep';
import { urlConfig } from 'src/config/urlConfig';
import { routerDom } from '@smartfire/glue-ui';
import { FormStatus } from 'src/api/FormStatus';

const { Link } = routerDom;

const isStepActive = (steps: { id: FormStep; link: string }[] | undefined, formStepFirm: FormStep[] | undefined) => {
    return steps?.every((f: { id: FormStep; link: string }) => {
        return formStepFirm?.includes(f.id);
    });
};

export const FormProgress: React.FC = () => {
    const firm = useFirm();
    const formStepFirm = firm?.formStep;
    const location = routerDom.useLocation();

    return (
        <div className={style.progress}>
            {formConfig.steps.map((step, index) => (
                <div
                    className={clsx(style.step, isStepActive(step.subSteps, formStepFirm) && style.active)}
                    key={index}
                >
                    <div className={style.index}>{index + 1}</div>
                    <div className={style.label} dangerouslySetInnerHTML={{ __html: step.label }} />

                    <div className={style.points}>
                        {step.subSteps &&
                            step.subSteps.map((s, i) => {
                                const stepNumber = FormStepOrder.indexOf(s.id) + 1;
                                const isPointActive =
                                    location.pathname === urlConfig.frontend.form['step' + stepNumber]?.base ?? false;

                                return (
                                    <Link
                                        to={{
                                            pathname: s.link,
                                        }}
                                        className={clsx(
                                            style.point,
                                            formStepFirm?.includes(s.id) && style.active,
                                            firm?.status !== FormStatus.SENT && isPointActive && style.current,
                                        )}
                                        key={i}
                                    >
                                        <span></span>
                                    </Link>
                                );
                            })}
                        {!step.subSteps && (
                            <div
                                className={clsx(
                                    style.point,
                                    window.location.pathname === urlConfig.frontend.form.step8.base && style.current,
                                    firm?.status === FormStatus.SENT && style.active,
                                )}
                            >
                                <span></span>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
