import { RenderContent } from '@smartfire/glue-ui/block';
import { useSetting } from '@smartfire/glue-ui';

const RenderGutenbergSetting = ({ settingKey }: { settingKey: string }) => {
    const setting = useSetting(settingKey);
    if (setting) {
        return <RenderContent content={setting} />;
    }
    return null;
};

export default RenderGutenbergSetting;
