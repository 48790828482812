import { useRef } from 'react';
import { registerBlock } from '@smartfire/glue-ui';
import { FormSelector } from '@smartfire/glue-ui/admin';
import { useEditor, useSmartProp, smartProps } from '@smartfire/glue-ui/block';
import style from './block.module.scss';
import { RenderHookForm } from '@smartfire/glue-ui/components';
import { Button } from 'src/components/Button';

function BlockFormulaire() {
    const [form, setForm] = useSmartProp('form');
    const ref = useRef<HTMLFormElement>(null);
    const { isEditing } = useEditor();

    return (
        <div className={style.block}>
            {isEditing && (
                <div>
                    <h3>Bloc Formulaire</h3>
                    <FormSelector value={form} setValue={setForm} />
                    <br />
                    <br />
                    <br />
                </div>
            )}
            {form && <RenderHookForm slug={form} ref={ref} />}
            <Button onClick={() => ref.current?.requestSubmit()}>Envoyer</Button>
        </div>
    );
}

export default registerBlock('bloc-formulaire', {
    Block: BlockFormulaire,
    smartProps: {
        form: smartProps.string.isRequired,
        title: smartProps.string.withDefault('Contactez-nous'),
    },
    setup: {
        title: 'Bloc Formulaire',
    },
});
