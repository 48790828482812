import { smartProps, EditableDiv, useInspectorControl, useSmartProp } from '@smartfire/glue-ui/block';
import { PanelBody, TextControl } from '@wordpress/components';
import { registerBlock } from '@smartfire/glue-ui';
import { Link } from '@smartfire/glue-ui/components';

import styles from './block.module.scss';

const Inspector = () => {
    const [href, setHref] = useSmartProp('href');
    return (
        <PanelBody title="Paramètres">
            <TextControl label="Lien" value={href} onChange={setHref} />
        </PanelBody>
    );
};

const Block = ({ href }: { href: string }) => {
    useInspectorControl(Inspector);

    return (
        <Link to={href}>
            <EditableDiv className={styles.link} smartProp="link" />
        </Link>
    );
};

export default registerBlock(
    'menu-item',
    {
        Block,
        smartProps: {
            href: smartProps.string.withDefault(''),
            link: smartProps.string.withDefault('Lien'),
        },
        setup: {
            title: 'Menu',
            parent: ['smartfire/header'],
        },
    },
    'smartfire',
);
