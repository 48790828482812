import type { FC, ReactNode } from 'react';
import useFirm from 'src/hook/useFirm';
import { FormStatus } from 'src/api/FormStatus';
import FormStep9 from 'src/pages/Form/FormStep9';

interface FormGuardProps {
    children: ReactNode;
}

export const FormGuard: FC<FormGuardProps> = ({ children }) => {
    const firm = useFirm();

    if (firm?.status === FormStatus.SENT) {
        return <FormStep9 />;
    } else {
        return <>{children}</>;
    }
};

export default FormGuard;
