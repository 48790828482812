import {
    EditableDiv,
    EditableImg,
    smartProps,
    useEditor,
    useInspectorControl,
    useSmartProp,
} from '@smartfire/glue-ui/block';
import { Link } from '@smartfire/glue-ui/components';
import { PanelBody, TextControl } from '@wordpress/components';
import { registerBlock } from '@smartfire/glue-ui';
import clsx from 'clsx';

import styles from './block.module.scss';

const Inspector = () => {
    const [href, setHref] = useSmartProp('href');
    return (
        <PanelBody title="Paramètres">
            <TextControl label="Lien de la tuile" value={href} onChange={setHref} />
        </PanelBody>
    );
};

const Block = ({ href }: { href: string }) => {
    useInspectorControl(Inspector);
    const { isEditing } = useEditor();

    return (
        <Link to={href}>
            <div className={clsx(styles.block, href && styles.clickable, !isEditing && styles.frontPage)}>
                <EditableImg className={styles.image} smartProp="image" />

                <EditableDiv
                    className={styles.title}
                    smartProp="title"
                    tagName="h3"
                    allowedFormats={['core/italic', 'core/text-color']}
                />
            </div>
        </Link>
    );
};

export default registerBlock(
    'vertical-tile',
    {
        Block,
        smartProps: {
            image: smartProps.image,
            title: smartProps.string.withDefault(''),
            href: smartProps.string.withDefault('/link'),
        },
        setup: {
            title: 'Tuile verticale',
            parent: ['smartfire/vertical-tiles'],
        },
    },
    'smartfire',
);
