import type { InputHTMLAttributes } from 'react';
import { useState } from 'react';
import { EditableText } from '@smartfire/glue-ui/block';
import { Controller, ControllerProps } from 'react-hook-form';
import clsx from 'clsx';

import ViewPassword from '../../../assets/view.png';

import style from './style.module.scss';

interface InputTextLabelInterface
    extends Omit<InputHTMLAttributes<any>, 'onChange' | 'defaultValue' | 'name'>,
        Omit<ControllerProps<any>, 'render'> {
    label?: string;
    formStyle?: boolean;
    margin?: boolean;
    isEditable?: boolean;
    smartProp?: string;
    isAdmin?: boolean;
    siretFormat?: boolean;
    adminFirms?: boolean;
    onlyNumber?: boolean;
    onlyLetter?: boolean;
    sx?: Object;
}

export const InputTextLabelController: React.FC<InputTextLabelInterface> = ({
    label,
    name,
    control,
    defaultValue = '',
    rules,
    shouldUnregister,
    formStyle = false,
    margin = true,
    isEditable = false,
    smartProp = null,
    isAdmin = false,
    siretFormat = false,
    adminFirms = false,
    onlyNumber = false,
    onlyLetter = false,
    sx,
    ...props
}) => {
    const [update, setUpdate] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const spacesFormat = (value: string) => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const allowOnlyNumber = (value: string) => {
        return value.replace(/[^0-9-]/g, '');
    };

    const allowOnlyLetter = (value: string) => {
        return value.replace(/[^a-zA-Z\s-é¨ë]/g, '');
    };

    return (
        <div
            className={clsx(
                style.input,
                formStyle && !isAdmin && style.form,
                style.fullWidth,
                margin === false && style.noMargin,
                !label && style.noLabel,
                isEditable && !update && style.update,
                adminFirms && style.adminFirms,
            )}
            style={sx}
        >
            {smartProp ? (
                <EditableText tagName={'label'} smartProp={smartProp} />
            ) : (
                label && <label htmlFor={name}>{label}</label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error, invalid, isTouched } }) => (
                    <>
                        <input
                            id={name}
                            {...props}
                            {...field}
                            onBlur={() => {
                                siretFormat && field.onChange(spacesFormat(field.value));
                                field.onBlur();
                            }}
                            onChange={(e) =>
                                onlyNumber
                                    ? field.onChange(allowOnlyNumber(e.target.value))
                                    : onlyLetter
                                    ? field.onChange(allowOnlyLetter(e.target.value))
                                    : field.onChange(e.target.value)
                            }
                            disabled={(isEditable && !update) || isAdmin}
                            type={props.type === 'password' && passwordShown ? 'text' : props.type}
                        />
                        {error && <div className={style.error}>{error.message}</div>}
                        {!isAdmin && !invalid && isTouched && !adminFirms && <div className={style.valid}></div>}
                        {props.type === 'password' && (
                            <div className={style.viewPassword} onClick={() => setPasswordShown(!passwordShown)}>
                                <img src={ViewPassword} alt="Afficher le mot de passe" />
                            </div>
                        )}
                    </>
                )}
                defaultValue={defaultValue ?? ''}
                rules={rules}
                shouldUnregister={shouldUnregister}
            />
            {isEditable && update === false && (
                <div className={style.updateButton} onClick={() => setUpdate(true)}>
                    Modifier
                </div>
            )}
        </div>
    );
};
