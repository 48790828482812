import { registerFormField, smartProps, useSmartProp, useInspectorControl, useEditor } from '@smartfire/glue-ui/block';
import { PanelBody, PanelRow, SelectControl, TextControl } from '@wordpress/components';
import { InputTextLabelController } from 'src/components/inputs/InputTextLabelController';
import { useForm, useFormContext } from 'react-hook-form';

type Props = {
    labelField: string;
    label: string;
    isRequired: boolean;
    typeField: string;
    placeholder: string;
};

const Inspector = () => {
    const [typeField, setTypeField] = useSmartProp('typeField');
    const [placeholder, setPlaceholder] = useSmartProp('placeholder');

    return (
        <PanelBody title="options" initialOpen={true}>
            <PanelRow>
                <SelectControl
                    value={typeField || 'text'}
                    label="Type du champ"
                    options={[
                        { label: 'Text', value: 'text' },
                        { label: 'Url', value: 'url' },
                        { label: 'Mail', value: 'email' },
                        { label: 'Nombre', value: 'number' },
                        { label: 'Téléphone', value: 'tel' },
                        { label: 'SIREN', value: 'siren' },
                    ]}
                    onChange={setTypeField}
                />
            </PanelRow>
            <PanelRow>
                <TextControl value={placeholder} onChange={setPlaceholder} label={'Placeholder'} />
            </PanelRow>
        </PanelBody>
    );
};

const InputText = ({ label, isRequired, typeField, labelField, placeholder }: Props) => {
    useInspectorControl(Inspector);
    const { isEditing } = useEditor();
    const form = useFormContext();
    const formAdmin = useForm();

    const rules = (required: boolean, type: string) => {
        let r = {};

        if (required) {
            const requiredRule = {
                required: 'Ce champ est requis.',
            };
            r = { ...r, ...requiredRule };
        }

        if (type === 'tel') {
            const patternTel = {
                pattern: {
                    value: /^(?:(?: \+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
                    message: 'Ce numéro n’est pas conforme. Veuillez saisir à nouveau les 10 chiffres.',
                },
            };
            r = { ...r, ...patternTel };
        }

        if (type === 'email') {
            const patternEmail = {
                pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Cet email n’est pas conforme. Veuillez le saisir à nouveau.',
                },
            };
            r = { ...r, ...patternEmail };
        }

        if (type === 'siren') {
            const patternSiren = {
                pattern: {
                    value: /^\d{9}$/,
                    message: 'Le format du numéro SIREN est non valide.',
                },
            };
            r = { ...r, ...patternSiren };
        }

        return r ?? undefined;
    };

    return (
        <InputTextLabelController
            label={labelField}
            name={label}
            formStyle={true}
            control={isEditing ? formAdmin.control : form.control}
            smartProp={'labelField'}
            isAdmin={isEditing}
            placeholder={placeholder}
            type={typeField === 'siren' ? 'text' : typeField}
            rules={rules(isRequired, typeField)}
        />
    );
};

export default registerFormField('input-text', {
    Block: InputText,
    type: 'text',
    smartProps: {
        typeField: smartProps.string.withDefault('text'),
        labelField: smartProps.string.withDefault('label'),
        placeholder: smartProps.string.withDefault('placeholder'),
    },
    setup: {
        title: 'Input Text',
    },
});
