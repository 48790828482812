import React from 'react';
import { CustomToaster } from 'src/components/CustomToaster';
import useScrollReset from '../../../utils/useScrollReset';

export const CommonLayout = ({ children }: { children: React.ReactNode }) => {
    useScrollReset();
    if (children)
        return (
            <>
                <div className={'frontend'}>{children}</div>
                <CustomToaster />
            </>
        );
    else return null;
};
