import { FormStep } from 'src/api/FormStep';
import { urlConfig } from 'src/config/urlConfig';

export const formConfig = {
    steps: [
        {
            label: 'Informations<br> entreprise',
            subSteps: [
                {
                    id: FormStep.BasicInfo,
                    link: urlConfig.frontend.form.step1.base,
                },
                {
                    id: FormStep.ContactInfo,
                    link: urlConfig.frontend.form.step2.base,
                },
            ],
        },
        {
            label: 'Régimes santé<br> et prévoyance',
            subSteps: [
                {
                    id: FormStep.MutuelleSanteChoix,
                    link: urlConfig.frontend.form.step3.base,
                },
                { id: FormStep.PrevoyanceChoix, link: urlConfig.frontend.form.step4.base },
            ],
        },
        {
            label: 'Correspondants<br> métiers',
            subSteps: [
                {
                    id: FormStep.ContactCorrespondantFasttFptt,
                    link: urlConfig.frontend.form.step5.base,
                },
                { id: FormStep.ContactCorrespondantConcentrateurHeures, link: urlConfig.frontend.form.step6.base },
                { id: FormStep.ContactCorrespondantProtectionSociale, link: urlConfig.frontend.form.step7.base },
            ],
        },
        {
            label: 'Transmission<br> des données',
        },
    ],
};
