import { glue } from '@smartfire/glue-ui';
import { colors } from '@material-ui/core';
import logo from './assets/logo.png';

glue.setFrontAvailableTranslations(['fr']);
glue.setEditingAvailableTranslations(['fr']);
glue.setSmartFormStatus(true);

glue.addFilter('glue.admin.login.title', () => <img src={logo} width="200" alt="logo" />);

const white = '#FFFFFF';

glue.addFilter('glue.theme.palette', () => ({
    primary: {
        light: '#DCDCDC',
        main: '#3D5560',
        dark: '#000000',
        contrastText: '#fff',
    },
    secondary: {
        light: '#EC6607',
        main: '#EC6607',
        dark: '#F39200',
        contrastText: '#ffffff',
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
    },
    link: '#EC6607',
    icon: '#EC6607',
    background: {
        default: '#F4F6F8',
        paper: '#FFF',
    },
    divider: colors.grey[200],
}));
