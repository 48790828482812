import React from 'react';
import RenderGutenbergSetting from 'src/components/RenderGutenbergSetting';
import { CommonLayout } from '../CommonLayout';

export const FrontendCommon = ({
    contentMobile = true,
    children,
}: {
    contentMobile?: boolean;
    children: React.ReactNode;
}) => {
    return (
        <CommonLayout>
            <RenderGutenbergSetting settingKey="header" />
            <div>{children}</div>
            <RenderGutenbergSetting settingKey="footer" />
        </CommonLayout>
    );
};
