export const options = [
    {
        key: 'norecieve',
        label: "Nous n'avons pas reçu le kit de bienvenue",
        email: 'isabelle.paineau@ag2rlamondiale.fr',
    },
    {
        key: 'noSiren',
        label: "Le SIREN n'est pas reconnu",
        email: 'isabelle.paineau@ag2rlamondiale.fr',
    },
    {
        key: 'noSecurityCode',
        label: 'Le code de sécurité remis dans le kit de connexion ne fonctionne pas',
        email: 'collecte@fastt.org',
    },
    {
        key: 'others',
        label: 'Autres',
        email: 'collecte@fastt.org',
    },
];
