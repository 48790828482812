import { createContext, FC, useEffect, useMemo, useState } from 'react';
import Firm from '../api/Firm';
import { useIdentity } from '@smartfire/glue-ui';
import { FirmIdentity } from '../api/FirmIdentity';
import { useFetcher, useResource } from 'rest-hooks';

export const FirmContext = createContext<Firm | undefined>(undefined);

export const FirmContextProvider: FC = ({ children }) => {
    const user = useIdentity();
    const fetchUser = useFetcher(Firm.me());
    const [valid, setValid] = useState(false);
    const firm = useResource(Firm.me(), valid ? {} : null);

    useEffect(() => {
        if (user instanceof FirmIdentity) {
            fetchUser({}).then(() => setValid(true));
        } else {
            setValid(false);
        }
    }, [user, fetchUser]);

    const returnValue = useMemo(() => {
        if (firm) {
            return firm;
        }
        // At page loading, user is connected but firm as not been loaded by rest-hook yet
        else if (user instanceof FirmIdentity) {
            return user as unknown as Firm;
        }
        return undefined;
    }, [user, firm]);

    return <FirmContext.Provider value={returnValue}>{children}</FirmContext.Provider>;
};
