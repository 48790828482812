import { FrontendCommon } from '../FrontendCommon';
import style from './style.module.scss';
import { LayoutParams, RenderSubRoutes } from '@smartfire/glue-ui';

export const MainLayout = ({ children }: LayoutParams) => {
    return (
        <FrontendCommon>
            <div className={style.content}>
                <RenderSubRoutes children={children} />
            </div>
        </FrontendCommon>
    );
};
