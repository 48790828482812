import type { InputHTMLAttributes } from 'react';
import { useState } from 'react';
import { ControllerProps, Controller } from 'react-hook-form';
import { EditableText } from '@smartfire/glue-ui/block';
import style from './style.module.scss';
import clsx from 'clsx';

interface OptionInterface {
    key: string;
    label: string;
    email?: string;
}

interface SelectInterface
    extends Omit<InputHTMLAttributes<any>, 'onChange' | 'defaultValue' | 'name'>,
        Omit<ControllerProps<any>, 'render'> {
    label?: string;
    smartProp?: string;
    isAdmin?: boolean;
    options: Array<OptionInterface>;
    defaultLabel?: string;
    isEditable?: boolean;
    adminFirms?: boolean;
}

export const SelectControl: React.FC<SelectInterface> = ({
    label,
    name,
    control,
    smartProp,
    rules,
    isAdmin,
    defaultValue,
    options,
    defaultLabel,
    isEditable = false,
    adminFirms = false,
}) => {
    const [update, setUpdate] = useState(false);

    return (
        <div
            className={clsx(
                style.select,
                isAdmin && style.admin,
                isEditable && !update && style.update,
                adminFirms && style.adminFirms,
            )}
        >
            {smartProp ? (
                <EditableText tagName={'label'} smartProp={smartProp} />
            ) : (
                label && <label htmlFor={name}>{label}</label>
            )}
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue ?? 'null'}
                render={({ field, fieldState: { error, invalid, isTouched } }) => (
                    <>
                        <select {...field} disabled={isEditable && !update}>
                            {defaultLabel && (
                                <option value={'null'} disabled>
                                    {defaultLabel}
                                </option>
                            )}
                            {options.map((option, index) => (
                                <option key={index} value={option.key}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {error && <div className={style.error}>{error.message}</div>}
                    </>
                )}
            />
            {isEditable && update === false && (
                <div className={style.updateButton} onClick={() => setUpdate(true)}>
                    Modifier
                </div>
            )}
        </div>
    );
};
