import type { InputHTMLAttributes } from 'react';
import { ControllerProps, Controller } from 'react-hook-form';
import { EditableText } from '@smartfire/glue-ui/block';
import style from './style.module.scss';
import clsx from 'clsx';

interface TextareaInterface
    extends Omit<InputHTMLAttributes<any>, 'onChange' | 'defaultValue' | 'name'>,
        Omit<ControllerProps<any>, 'render'> {
    label?: string;
    smartProp?: string;
    isAdmin?: boolean;
}

export const TextareaControl: React.FC<TextareaInterface> = ({
    label,
    name,
    control,
    smartProp,
    rules,
    isAdmin,
    ...props
}) => {
    return (
        <div className={clsx(style.textarea, isAdmin && style.admin)}>
            {smartProp ? (
                <EditableText tagName={'label'} smartProp={smartProp} />
            ) : (
                label && <label htmlFor={name}>{label}</label>
            )}
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState: { error, invalid, isTouched } }) => (
                    <>
                        <textarea {...props} {...field}></textarea>
                        {error && <div className={style.error}>{error.message}</div>}
                        {!invalid && isTouched && <div className={style.valid}></div>}
                    </>
                )}
            />
        </div>
    );
};
