import {
    smartProps,
    ChildrenBlock,
    useEditor,
    EditableDiv,
    useInspectorControl,
    useSmartProp,
} from '@smartfire/glue-ui/block';
import { useState } from 'react';
import { InnerBlocks } from '@wordpress/block-editor';
import { registerBlock, useAuthentication } from '@smartfire/glue-ui';
import { PanelBody, TextControl } from '@wordpress/components';
import Email from '../../assets/email.svg';
import Shield from '../../assets/shield.svg';
import Folder from '../../assets/folder.svg';
import Menu from '../../assets/menu.svg';
import Close from '../../assets/close.svg';
import styles from './block.module.scss';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { Link } from '@smartfire/glue-ui/components';
import useFirm from 'src/hook/useFirm';
import { urlConfig } from 'src/config/urlConfig';
import Logout from '../../assets/logout.png';
import { FirmIdentity } from 'src/api/FirmIdentity';

const Inspector = () => {
    const [linkForm, setLinkForm] = useSmartProp('linkForm');
    const [linkFile, setLinkFile] = useSmartProp('linkFile');

    return (
        <PanelBody title="Paramètres">
            <TextControl label="Lien Formulaire" value={linkForm} onChange={setLinkForm} />
            <TextControl label="Lien Dossier en cours" value={linkFile} onChange={setLinkFile} />
        </PanelBody>
    );
};

export const Header = ({
    linkFile,
    loginContext = false,
    title = 'Branche-travail-temporaire.org',
}: {
    linkFile: string;
    loginContext?: boolean;
    title?: string;
}) => {
    const { isEditing } = useEditor();
    const firm = useFirm();
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useMediaQuery(`maxWidth: 950px`);

    useInspectorControl(Inspector);
    const authentication = useAuthentication();

    return (
        <div className={styles.block}>
            <div className={styles.top}>
                {loginContext ? (
                    <div className={styles.title}>{title}</div>
                ) : (
                    <a href="/">
                        <EditableDiv className={styles.title} smartProp="title" />
                    </a>
                )}
                {(!isMobile || (isMobile && menuOpen)) && (
                    <div className={styles.account}>
                        {loginContext === false && (
                            <>
                                <img src={Shield} className={styles.shield} alt="" />
                                <p>{firm?.file.raisonSociale}</p>
                            </>
                        )}
                        <Link to={urlConfig.frontend.contact.base}>
                            <img src={Email} className={styles.email} alt="" />
                            <div className={styles.labelEmail}>Contact</div>
                        </Link>
                        {authentication.currentUser instanceof FirmIdentity && (
                            <Link
                                className={styles.logout}
                                to={urlConfig.frontend.auth.logout.base}
                                title={'Se déconnecter'}
                            >
                                <img src={Logout} alt="logout" />
                                <div>Se déconnecter</div>
                            </Link>
                        )}
                    </div>
                )}
                {isMobile && (
                    <img
                        src={menuOpen ? Close : Menu}
                        className={styles.openMenu}
                        onClick={() => setMenuOpen(!menuOpen)}
                        alt=""
                    />
                )}
            </div>
            {!loginContext && (
                <div className={clsx(styles.bottom, menuOpen && styles.open)}>
                    <div className={styles.menu}>
                        {isEditing ? (
                            <div className={styles.innerBlocksWrapper}>
                                <InnerBlocks allowedBlocks={['smartfire/menu-item']} />
                            </div>
                        ) : (
                            <div className={styles.childrenBlockWrapper}>
                                <ChildrenBlock />
                            </div>
                        )}
                    </div>
                    <Link to={linkFile} className={styles.shortcut}>
                        <EditableDiv className={styles.link} smartProp="shortcut" />
                        <img src={Folder} className={styles.folder} alt="" />
                    </Link>
                </div>
            )}
        </div>
    );
};

export default registerBlock(
    'header',
    {
        Block: Header,
        smartProps: {
            title: smartProps.string.withDefault(''),
            shortcut: smartProps.string.withDefault(''),
            linkForm: smartProps.string.withDefault(''),
            linkFile: smartProps.string.withDefault(''),
        },
        setup: {
            title: 'Header',
        },
    },
    'smartfire',
);
