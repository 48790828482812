import type { FC } from 'react';
import { urlConfig } from 'src/config/urlConfig';
import { routerDom } from '@smartfire/glue-ui';
import useFirm from '../../hook/useFirm';

const { useLocation, Navigate } = routerDom;

export const GuestGuard: FC = ({ children }) => {
    const firm = useFirm();
    const location = useLocation();

    if (firm) {
        // Firm is logged
        const locationState = location.state as { redirect?: Location };
        if (locationState?.redirect) {
            return <Navigate to={locationState.redirect} />;
        } else {
            return <Navigate to={urlConfig.frontend.home.base} />;
        }
    }
    // Access granted
    return <>{children}</>;
};
