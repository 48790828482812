import Firm from './Firm';
import { Identity } from '@smartfire/glue-ui';

export class FirmIdentity extends Firm implements Identity {
    constructor(data: Firm) {
        super();
        this.id = data.id;
        this.file = data.file;
        this.formStep = data.formStep;
        this.dateAdded = data.dateAdded;
        this.status = data.status;
        this.secretCode = data.secretCode;
        this.tosAcceptationDate = data.tosAcceptationDate;
    }

    suitableFor(role: string): boolean {
        return role === 'ROLE_FIRM';
    }
}
